import React, { createContext, useState, useRef, useEffect } from "react";
import axios from "../lib/axios-instance";
import { resetUrl, addColorToFacades, formattedFloors } from "../utils/utils";
import { feature, featureCollection } from "@turf/helpers";
import {
  faMinus,
  faLeftLong,
  faImage,
  faGlobe,
  faCheck,
  faPlus,
  faCircleInfo,
  faEye,
  faEyeSlash,
  faMap
} from "@fortawesome/free-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

export const FacadeContext = createContext();

export default function FacadeProvider({ children }) {
  const [projects, setProjects] = useState();
  const [currentProjectId, setCurrentProjectId] = useState();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentFacadeSetId, setCurrentFacadeSetId] = useState(null);
  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);
  const draw = useRef();
  const [selectedFacadeSet, setSelectedFacadeSet] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [drawnFacades, setDrawnFacades] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [facadeSetPoints, setFacadeSetPoints] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const facadeDrawnPoints = useRef({
    type: "FeatureCollection",
    features: [],
  });
  const [lng, setLng] = useState(12.57);
  const [lat, setLat] = useState(55.67);
  const [zoom, setZoom] = useState(15);
  const [selectedFacades, setSelectedFacades] = useState([]);
  const [showObservationForm, setShowObservationForm] = useState(false);
  const [showPoiForm, setShowPoiForm] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [attributePrimary, setAttributePrimary] = useState(null);
  const [attributeSecondary, setAttributeSecondary] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);
  const [currentComment, setCurrentComment] = useState(null);
  const [drawnFeatures, setDrawnFeatures] = useState([]);
  const [floors, setFloors] = useState(0);
  const [successfullySubmitted, setsuccessfullySubmitted] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [currentPoiFeature, setCurrentPoiFeature] = useState({});
  const [drawnPois, setDrawnPois] = useState([]);
  const [isOffline, setIsOffline] = useState(false);
  const [visualizationMode, setVisualizationMode] = useState(false);
  const [isStatelliteMap, setIsSatelliteMap] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);
  const hasDismissedOfflineMsg = useRef(false);
  const initialLoad = useRef(true);
  const observationAttributes = useRef({});

  const [isLoading, setIsLoading] = useState(true);

  library.add(
    faMinus,
    faLeftLong,
    faImage,
    faGlobe,
    faCheck,
    faPlus,
    faCircleInfo,
    faEye,
    faEyeSlash,
    faMap
  );

  window.addEventListener("online", handleOfflineStatusChange);
  window.addEventListener("offline", handleOfflineStatusChange);
  function handleOfflineStatusChange(event) {
    event.type === "online" ? setIsOffline(false) : setIsOffline(true);
    hasDismissedOfflineMsg.current = false;
  }

  formattedFloors(floors);

  const getProjects = async () => {
    const response = await axios.get(`/projects`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    setProjects(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!currentProjectId) return;
    initialLoad.current = true;

    Promise.all([
      getObservations(),
      getPoiObservations(),
      getAttributes(),
    ]).then(([observationData, poiObservationData, attributeData]) => {
      formatAttributes(attributeData.data);
      formatObservations(observationData.data);
      formatPoiObservations(poiObservationData.data);
    });
  }, [currentProjectId]);

  const handleClickSecondaryAttribute = (code) => {
    setAttributeSecondary((attributeSecondary) =>
      attributeSecondary.includes(code)
        ? attributeSecondary.filter((a) => a !== code)
        : [...attributeSecondary, code]
    );
  };

  const getObservations = () => {
    return axios.get(`/projects/${currentProjectId}/facade_observations`);
  };

  const getAttributes = async () => {
    return axios.get(`/projects/${currentProjectId}/attributes`);
  };

  const getPoiObservations = async () => {
    return axios.get(`/projects/${currentProjectId}/poi_observations`);
  };

  const formatObservations = (data) => {
    let projectObservations = data;

    if (currentFacadeSetId) {
      let facadeObservations = projectObservations.filter(
        (el) => el.facade_set_id == currentFacadeSetId
      );

      // Match facade set with observations and assign color
      setSelectedFacadeSet((selectedFacadeSet) => {
        selectedFacadeSet.features.map((facadeSetFeature) => {
          let matchingObservations = facadeObservations.filter(
            (o) =>
              o.facade_feature_id ===
              facadeSetFeature.properties.facade_feature_id
          );
          facadeSetFeature.properties.observations = matchingObservations;

          matchingObservations.length > 0
            ? (facadeSetFeature.properties.color = getColor(
                matchingObservations.at(-1)
              ))
            : (facadeSetFeature.properties.color = null);
        });
        return selectedFacadeSet;
      });

      let drawnFacadeObservationFeatures =
        getDrawnFacadeObservationFeatures(facadeObservations);
      setDrawnFacades(featureCollection(drawnFacadeObservationFeatures));
    } else {
      let drawnFacadeObservationFeatures =
        getDrawnFacadeObservationFeatures(projectObservations);
      setDrawnFacades(featureCollection(drawnFacadeObservationFeatures));
    }
  };

  const getDrawnFacadeObservationFeatures = (observationFeatures) => {
    return observationFeatures
      .filter((o) => o.user_defined)
      .map((d) => {
        let f = feature(d.geometry, { observations: [d] });
        f.properties.color = getColor(d);
        return f;
      });
  };

  const formatPoiObservations = (data) => {
    setDrawnPois(data.map((p) => p.geometry));
  };

  const formatAttributes = (data) => {
    let categorized = {};
    categorized.facadePrimary = data.facadeAttributes.filter(
      (f) => f.is_primary
    );
    categorized.facadeSecondary = data.facadeAttributes.filter(
      (f) => f.is_secondary
    );

    categorized.poiPrimary = data.poiAttributes.filter((f) => f.is_primary);
    categorized.poiSecondary = data.poiAttributes.filter((f) => f.is_secondary);

    categorized.facadePrimary = addColorToFacades(categorized.facadePrimary);

    observationAttributes.current = categorized;
  };

  const clearForm = () => {
    setAttributePrimary(null);
    setAttributeSecondary([]);
    setCurrentComment(null);
    setCurrentImage(null);
    setSelectedFacades([]);
    setDrawnFeatures([]);
    draw.current.deleteAll();
    setShowPoiForm(false);
    setShowObservationForm(false);
    setFloors(0);
  };

  const clearAll = () => {
    clearForm();

    setCurrentFacadeSetId(null);
    setCurrentProjectId(null);
    setSelectedProject(null);

    setSelectedFacadeSet({
      type: "FeatureCollection",
      features: [],
    });

    setDrawnFacades({
      type: "FeatureCollection",
      features: [],
    });

    setFacadeSetPoints({
      type: "FeatureCollection",
      features: [],
    });

    setDrawnPois([]);

    facadeDrawnPoints.current = {
      type: "FeatureCollection",
      features: [],
    };

    observationAttributes.current = {};

    resetUrl();

    initialLoad.current = true;
  };

  const submitFacadeObservation = (userId) => {
    const formData = new FormData();

    if (selectedFacades.length > 0) {
      selectedFacades.forEach((facadeId) => {
        appendDefault(formData, userId);
        let geometry = selectedFacadeSet.features.find(
          (s) => s.properties.facade_feature_id === facadeId
        )?.geometry;
        formData.append(
          "facade_observations[][geometry]",
          JSON.stringify(geometry)
        );
        formData.append("facade_observations[][facade_feature_id]", facadeId);
        formData.append("facade_observations[][user_defined]", false);
      });
    }

    if (drawnFeatures.length > 0) {
      drawnFeatures.forEach((drawnFeature) => {
        appendDefault(formData, userId);
        let geometry = drawnFeature.geometry;
        formData.append(
          "facade_observations[][geometry]",
          JSON.stringify(geometry)
        );
        formData.append(
          "facade_observations[][facade_feature_id]",
          drawnFeature.properties.facade_feature_id
        );
        formData.append("facade_observations[][user_defined]", true);
      });
    }

    axios
      .post(`/facade_observation_collection`, formData)
      .then(function (response) {
        response?.data.forEach((el) => {
          if (!el.user_defined) {
            setSelectedFacadeSet((selectedFacadeSet) => {
              let updatedFeatures = selectedFacadeSet.features.map((f) => {
                if (f.properties.facade_feature_id === el.facade_feature_id) {
                  f.properties.observations.push(el);
                  f.properties.color = getColor(el);
                }
                return f;
              });
              return featureCollection(updatedFeatures);
            });

            setFacadeSetPoints((facadeSetPoints) => {
              let updatedFeatures = facadeSetPoints.features.map((p) => {
                if (p.properties.facade_feature_id === el.facade_feature_id)
                  p.properties.observations.push(el);
                return p;
              });
              return featureCollection(updatedFeatures);
            });
          } else {
            let f = feature(el.geometry, { observations: [el] });
            f.properties.color = getColor(el);

            setDrawnFacades((drawnFacades) => ({
              ...drawnFacades,
              features: [...drawnFacades.features, f],
            }));
          }
        });
        clearForm();
        setsuccessfullySubmitted(true);
        setTimeout(() => {
          setsuccessfullySubmitted(false);
        }, 2000);
      })
      .catch(function (error) {
        setSubmitFailed(true);
      });

    clearForm();
  };

  const handleClickOpenObservationsForm = () => {
    if (selectedFacades.length > 0 || drawnFeatures.length > 0) {
      setShowObservationForm(true);
    }
  };

  const handleClosePoiForm = () => {
    clearForm();
  };

  const handleCloseActionsContainer = () => {
    setShowObservationForm(false);
    setFloors();
    setAttributePrimary(null);
    setAttributeSecondary([]);
    setSubmitFailed(false);
  };

  const handleOpenSidebar = () => {
    setIsSidebarOpen(false);
    clearAll();
    getProjects();
  };

  const handleImageChange = (file) => {
    setCurrentImage(file);
    setCurrentImageUrl(URL.createObjectURL(file));
  };

  const appendDefault = (formData, userId) => {
    let u = userId.replaceAll('"', "");
    formData.append("facade_observations[][user_id]", u);
    formData.append("facade_observations[][facade_set_id]", currentFacadeSetId);
    formData.append("facade_observations[][project_id]", currentProjectId);
    if (attributePrimary)
      formData.append(
        "facade_observations[][observation_attributes_primary]",
        attributePrimary
      );
    if (attributeSecondary)
      formData.append(
        "facade_observations[][observation_attributes_secondary]",
        attributeSecondary
      );
    if (currentComment)
      formData.append("facade_observations[][notes]", currentComment);
    if (currentImage)
      formData.append("facade_observations[][image]", currentImage);
    if (floors)
      formData.append(
        "facade_observations[][number_of_floors]",
        formattedFloors(floors)
      );
  };
  const getColor = (observation) => {
    if (!observationAttributes.current) return;
    if (observation.observation_attributes_primary !== null) {
      return observationAttributes.current.facadePrimary.find(
        (f) => f.code === observation.observation_attributes_primary
      )?.color;
    }
  };

  const getColorQty = () => {
    if (!observationAttributes.current) return;

    if (observationAttributes.current.facadePrimary !== null) {
      observationAttributes?.current.facadePrimary.map((c) => {
        c["quantity"] = 0;
        selectedFacadeSet.features.map((s) => {
          if (s.properties.color == c.color) {
            c.quantity++;
          }
        });
        drawnFacades.features.map((d) => {
          if (d.properties.color == c.color) {
            c.quantity++;
          }
        });
      });
    }
  };

  useEffect(() => {
    if (observationAttributes.current.facadePrimary !== undefined) {
      getColorQty();
    }
  }, [visualizationMode]);

  return (
    <FacadeContext.Provider
      value={{
        projects,
        setProjects,
        isSidebarOpen,
        setIsSidebarOpen,
        selectedFacadeSet,
        setSelectedFacadeSet,
        currentFacadeSetId,
        setCurrentFacadeSetId,
        mapContainer,
        map,
        setMap,
        lng,
        setLng,
        lat,
        setLat,
        zoom,
        setZoom,
        selectedFacades,
        setSelectedFacades,
        showObservationForm,
        setShowObservationForm,
        attributePrimary,
        setAttributePrimary,
        currentImage,
        setCurrentImage,
        currentComment,
        setCurrentComment,
        getProjects,
        getObservations,
        clearForm,
        handleClickOpenObservationsForm,
        handleCloseActionsContainer,
        handleOpenSidebar,
        submitFacadeObservation,
        drawnFeatures,
        setDrawnFeatures,
        floors,
        setFloors,
        formattedFloors,
        currentImageUrl,
        setCurrentImageUrl,
        successfullySubmitted,
        setsuccessfullySubmitted,
        isOffline,
        hasDismissedOfflineMsg,
        submitFailed,
        setSubmitFailed,
        drawnFacades,
        setDrawnFacades,
        draw,
        initialLoad,
        facadeSetPoints,
        facadeDrawnPoints,
        currentPoiFeature,
        setCurrentPoiFeature,
        showPoiForm,
        setShowPoiForm,
        handleClosePoiForm,
        observationAttributes,
        handleImageChange,
        attributeSecondary,
        setAttributeSecondary,
        handleClickSecondaryAttribute,
        currentProjectId,
        setCurrentProjectId,
        showInfo,
        setShowInfo,
        drawnPois,
        setDrawnPois,
        visualizationMode,
        setVisualizationMode,
        setFacadeSetPoints,
        selectedProject,
        setSelectedProject,
        isStatelliteMap,
        setIsSatelliteMap,
        isLoading,
        setIsLoading
      }}
    >
      {children}
    </FacadeContext.Provider>
  );
}
