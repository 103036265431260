import React, { useState, useEffect } from "react";
import axios from "../lib/axios-instance";
import ViewData from "./ViewData";
import FacadeSetForm from "./FacadeSetForm"
import { defaultPrimaryAttributes } from "../utils/utils";

const ShowProject = (props) => {
  const [project, setProject] = useState(JSON.parse(props.project));
  const [availableUsers, setAvailableUsers] = useState(JSON.parse(props.users));

  const [selectedTab, setSelectedTab] = useState("project");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    let hash = window.location.hash;

    if (hash !== "") setSelectedTab(hash.split("#")[1]);
  }, []);

  useEffect(() => {
    window.location.hash = "#" + selectedTab;
  }, [selectedTab]);

  const getBaseUrl = () => {
    return window.location.href.split("#")[0];
  };

  useEffect(() => {
    if (successMessage !== "")
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
  }, [successMessage]);

  const updateProjectAttributes = (updatedAttribute, typeName, multiple = false) => {
    let type = typeName === "POI" ? 'poi_observation_attribute_definitions' : 'facade_observation_attribute_definitions';
    let updatedExisting = false;
    setProject((project) => {
      let updatedAttributes = project[type].map((a) => {
        if(a.id === updatedAttribute.id) {
          a = updatedAttribute;
          updatedExisting = true;
        }
        return a;
      })
      if(!updatedExisting) updatedAttributes.push(updatedAttribute);
      return {...project, [type]: updatedAttributes}
    })
    if(updatedExisting) {
      setSuccessMessage("Attribute updated");
    } else {
      multiple ? setSuccessMessage("Default attributes created") : setSuccessMessage("Attribute created");
    }
    
  }

  const ProjectSettings = () => {
    // const [projectNameInput, setProjectNameInput] = useState(project.name);

    const submitArchiveProject = () => {
      axios
        .put(`/admin/projects/${project.id}/archive`)
        .then((res) => {
          setProject({ ...project, archived_on: new Date() });
          setSuccessMessage("Project archived");
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const submitUnarchiveProject = () => {
      axios
        .put(`/admin/projects/${project.id}/unarchive`)
        .then((res) => {
          setProject({ ...project, archived_on: null });
          setSuccessMessage("Project un-archived");
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <div className="show-project-content show active" id="project">
        <div className="mb-4">
          Created on { new Date(project.created_at).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
          {project.created_by && (
            ` by ${project.created_by.email}`
          )}
        </div>

        <hr />

        {/* <h3>Project details</h3>

        <input type="text" className="form-control form-control-sm w-50" id="search-user-input" value={projectNameInput} onChange={(e) => setProjectNameInput(e.target.value)} />
        <button className="btn btn-primary" onClick={() => submitUpdateProjectName}>Update</button>

  
        <hr/> */}

        {project.archived_on === null && (
          <>
            <h3>Archive project</h3>
            <p>
              If you archive a project, it will no longer show up for it's
              users, but you can still download the data
            </p>
            <button
              className="btn btn-warning"
              onClick={() => submitArchiveProject()}
            >
              Archive project
            </button>
          </>
        )}
        {project.archived_on !== null && (
          <>
            <h3>Un-archive project</h3>
            <p>
              If you un-archive a project, it will again show up for it's users
              on their home page
            </p>
            <button
              className="btn btn-warning"
              onClick={() => submitUnarchiveProject()}
            >
              Un-archive project
            </button>
          </>
        )}
      </div>
    );
  };

  const Categories = (props) => {
    
    const { attributes, typeName } = props;
    const [editAttribute, setEditAttribute] = useState('');
    const [showNewAttribute, setShowNewAttribute] = useState(false);

    const primaryAttributes = attributes.filter(a => a.is_primary);
    const secondaryAttributes = attributes.filter(a => a.is_secondary);

    const createAttribute = (attribute, multiple = false) => {
      let data = {};

      let properties = {
        order: attribute.order,
        code: attribute.code,
        description: attribute.description,
        is_primary: attribute.primary,
        is_secondary: !attribute.primary,
        project_id: project.id
      }

      if(typeName === "Facade") {
        properties['help_text'] = attribute.helpText;
        data['facade_observation_attribute_definition'] = properties;
      }
      if(typeName === "POI") data['poi_observation_attribute_definition'] = properties;
      
      let url = typeName === "Facade" ? `/admin/attributes`: `/admin/poi-attributes`

      axios
        .post(url, data)
        .then((res) => {
          updateProjectAttributes(res.data, typeName, multiple); 
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const updateAttribute = (attribute) => {
      let data = {};

      let properties = {
        order: attribute.order,
        code: attribute.code,
        description: attribute.description
      }

      if(typeName === "Facade") {
        properties['help_text'] = attribute.helpText;
        data['facade_observation_attribute_definition'] = properties;
      }
      if(typeName === "POI") data['poi_observation_attribute_definition'] = properties;

      let url = typeName === "Facade" ? `/admin/attributes/${attribute.id}`: `/admin/poi-attributes/${attribute.id}`

      axios
        .put(url, data)
        .then((res) => {
          updateProjectAttributes(res.data, typeName)
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // const deleteAttribute = () => {

    // }

    const addDefaultPrimaryAttributes = () => {
      defaultPrimaryAttributes.forEach((attribute) => {
        createAttribute(attribute, true);
      })
    }

    const AttributeForm = (props) => {

      const { newAttribute, primary } = props;
  
      const attribute = newAttribute ? {} : props.attribute;

      const [validInput, setValidInput] = useState(false);
  
      const [order, setOrder] = useState(attribute.order || '');
      const [code, setCode] = useState(attribute.code || '');
      const [description, setDescription] = useState(attribute.description || '');
      const [helpText, setHelpText] = useState(attribute.help_text || '');

      const numberRe = /^[0-9\b]+$/;

      useEffect(() => {
        if(order === '' || code === '' || !numberRe.test(order) ) {
          if(validInput) setValidInput(false);
        } else {
          if(!validInput) setValidInput(true);
        }

      }, [order, code, description]);

      const createAttributeObj = () => {
        let obj = {
          order,
          code,
          description,
          helpText,
          primary
        }
        if(!newAttribute) obj.id = attribute.id;
        return obj;
      }

      const handleCreateAttribute = () => {
        let newAttributeProps = createAttributeObj();

        createAttribute(newAttributeProps);
      }

      const handleUpdateAttribute = () => {
        let updateAttributeProps = createAttributeObj();

        updateAttribute(updateAttributeProps);
      }
  
      
      return (
        <div className="my-3">
          <div className="row g-3" >
            <div className="col-md-3">
              <label for="input-cude" className="form-label small">Code *</label>
              <input type="text" name="code" id="input-code" className="form-control form-control-sm" value={code} onChange={(e) => setCode(e.target.value)} />
            </div>
            <div className="col-md-6">
              <label for="input-description" className="form-label small">Description {(typeName === "Facade" && primary) && "*"}</label>
              <input type="text" name="description" id="input-description" className="form-control form-control-sm" value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className="col-md-3">
              <label for="input-order" className="form-label small">Order (numbers only) *</label>
              <input type="number" name="order" className="form-control form-control-sm" id="input-order" value={order} onChange={(e) => setOrder(e.target.value)} />
            </div>
            
            {typeName === "Facade" && (
              <div className="col-md-12">
                <label for="input-help-text" className="form-label small">Help Text</label>
                <textarea name="help_text" className="form-control form-control-sm" id="input-help-text" value={helpText} onChange={(e) => setHelpText(e.target.value)} />
              </div>
            )}
           
              {!newAttribute && (
                <div className="d-flex">
                  <button className="btn btn-sm btn-primary" onClick={() => handleUpdateAttribute()} disabled={!validInput} >Update attribute</button>
                  {/* <button className="btn btn-sm btn-warning ms-2" onClick={() => deleteAttribute()} >Delete attribute</button> */}
                  <button className="btn btn-sm btn-info ms-2" onClick={() => setEditAttribute('')} >Close</button>
                </div>
              )}
  
              {newAttribute && (
                <div className="d-flex">
                  <button className="btn btn-sm btn-primary" onClick={() => handleCreateAttribute()} disabled={!validInput}>Create attribute</button>
                  <button className="btn btn-sm btn-info ms-2" onClick={() => showNewAttribute()} >Close</button>
                </div>
              )}
              
          </div>
        </div>
      )
    }

    return (
      <div className="show-project-content show active" id="project">
        <h3 className="m-0">
          {typeName === "Facade" ? "Facade categories" : "Point of Interest categories"}
        </h3>
        <p className="mt-2">
          {typeName === "Facade" && "These are the attributes users can assign to pre-set facade sets or geometries drawn with the Line tool. \n The primary attributes are single-chice and typically the A-E rating of a facade quality. Secondary attributes are other qualities you might also want to record about the facade, such as Shaded/Overhang, Active edge-zone, etc. These are multiple-choice."}

          {typeName === "POI" && "POI categories are the attributes users can assign to places marked with the Point tool. You can use these to mark doors/entrances, benches, trees, and so on. Primary attributes are single-choice, while the secondary attributes are multiple-choice."}
        </p>
        <hr />
        <div className="d-flex align-items-center">
          <h3 className="m-0">Primary attributes</h3>
          {/* HEJ */}
          <div
            onClick={() => {
              setShowNewAttribute('primary');
              setEditAttribute('');
            }}
            className="ms-3 btn btn-sm btn-primary"
          >
            New
          </div>
        </div>

        {showNewAttribute === 'primary' && <AttributeForm newAttribute={true} primary={true} />}

        <ul className="list-group list-group-flush list-group-sm">
          {primaryAttributes.sort((a, b) => a.order - b.order).map((attribute) => {
            return (
              <li className="list-group-item flex-column align-items-start" key={attribute.id}>
                <div className="w-100 d-flex justify-content-between align-items-start">
                  <p className="my-0 flex-shrink-1"><span className="fw-bold">{attribute.code}</span> - {attribute.description}</p>
                  <div className="d-flex align-items-start">
                    <span className="cursor-pointer badge rounded-pill text-bg-primary mr-2 fw-normal" onClick={() => setEditAttribute(editAttribute => editAttribute === attribute.id ? '' : attribute.id)}>
                      Edit attribute
                    </span>
                  </div>
                </div>
                {(editAttribute == attribute.id) && <AttributeForm newAttribute={false} attribute={attribute} primary={true} />}
              </li>
            );
          })}
        </ul>
        {primaryAttributes.length === 0 &&  (
          <div className="mt-2 ms-3">
            <div className="warning fst-italic">No primary attributes in project</div>

            {typeName === "Facade" && <button className="btn btn-sm btn-primary mt-2" onClick={() => addDefaultPrimaryAttributes()}>Add default (A-E)</button>}
          </div>
        )}
       
        <hr />

        <div className="d-flex align-items-center">
          <h3 className="m-0">{typeName} - secondary attributes</h3>
          <div className="ms-3 btn btn-sm btn-primary" onClick={() => {
            setShowNewAttribute('secondary');
            setEditAttribute('');
          }}>New</div>
        </div>

        {showNewAttribute === 'secondary' && <AttributeForm newAttribute={true} primary={false} />}

        <ul className="list-group list-group-flush list-group-sm">

          {secondaryAttributes.sort((a, b) => a.order - b.order).map((attribute) => {
            return (
              <li className="list-group-item flex-column align-items-start" key={attribute.id}>
                <div className="w-100 d-flex justify-content-between align-items-start">
                  <p className="my-0 flex-shrink-1"><span className="fw-bold">{attribute.code}</span> - {attribute.description}</p>
                  <div className="d-flex align-items-start">
                    <span className="cursor-pointer badge rounded-pill text-bg-primary mr-2 fw-normal" onClick={() => setEditAttribute(editAttribute => editAttribute === attribute.id ? '' : attribute.id)}>
                      Edit attribute
                    </span>
                  </div>
                </div>
                {(editAttribute == attribute.id) && <AttributeForm newAttribute={false} attribute={attribute} primary={false} />}
              </li>
            );
          })}
        </ul>
        {secondaryAttributes.length == 0 && (
          <div className="warning fst-italic ms-3 mt-3">No secondary attributes in project</div>
        )}
        
      </div>
    );
  };


  const FacadeSets = () => {

    const [showNewFacadeSet, setShowNewFacadeSet] = useState(false);

    const submitArchiveFacadeSet = (facadeSetId) => {
      axios
        .put(`/admin/projects/${project.id}/facade_sets/${facadeSetId}/archive`)
        .then((res) => {
          setProject((project) => {
            const updatedFacadeSets = project.facade_sets.map((facade_set) => {
              if (facade_set.id == facadeSetId)
                return { ...facade_set, archived_on: new Date() };
              return facade_set;
            });
            return { ...project, facade_sets: updatedFacadeSets };
          });

          setSuccessMessage("Facade set archived");
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const handleCreatedFacadeSet = (facadeSet) => {
      setProject(project => ({ ...project, facade_sets: [...project.facade_sets, facadeSet] }));

      setShowNewFacadeSet(false);

      setSuccessMessage("Added facade set");
    }

    return (
      <div className="show-project-content" id="facade-sets">
        <div className="d-flex align-items-center">
          <h3 className="m-0">Facade sets</h3>
          <div
            onClick={() => setShowNewFacadeSet(true)}
            className="ms-3 btn btn-sm btn-primary"
          >
            New facade set
          </div>
        </div>

        {showNewFacadeSet && <FacadeSetForm projectId={project.id} handleCreatedFacadeSet={handleCreatedFacadeSet} />}

        <p className="mt-2">
          Facade sets are pre-drawn facades (or sidewalks, etc.), which makes it faster to use the app in the field, as you don't have to draw the facades, but can simply click on the pre-drawn lines.
        </p>

        <ul className="list-group list-group-flush list-group-sm">
          {project.facade_sets?.map((facade_set) => {
            return (
              <li className="list-group-item flex-column align-items-start">
                <div className="w-100 d-flex justify-content-between align-items-start">
                  <div>
                    <p className="my-0 flex-shrink-1">
                      <a href={getBaseUrl() + "/facade_sets/" + facade_set.id}>
                        {facade_set.name}
                      </a>
                    </p>
                  </div>
                  
                  <div className="d-flex align-items-start">
                    {facade_set.archived_on !== null && (
                      <span className="badge rounded-pill text-bg-warning mr-2 fw-normal">
                        Archived
                      </span>
                    )}

                    {facade_set.archived_on === null && (
                      <span
                        className="badge rounded-pill text-bg-primary mr-2 fw-normal"
                        onClick={() => submitArchiveFacadeSet(facade_set.id)}
                      >
                        Archive
                      </span>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
          {project.facade_sets.length === 0 && (
            <span className="warning fst-italic mt-2">
              No Facade Sets in project
            </span>
          )}
        </ul>
      </div>
    );
  };

  const Users = () => {
    const [searchUserInput, setSearchUserInput] = useState("");

    const addUserToProject = (user) => {
      axios
        .post(`/admin/project_memberships/`, {
          user_id: user.id,
          project_id: project.id,
        })
        .then((res) => {
          setProject((project) => {
            return { ...project, users: [...project.users, user] };
          });
          setAvailableUsers((availableUsers) =>
            availableUsers.filter((u) => u.id !== user.id)
          );
          setSuccessMessage("Added user to project");
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const removeUserFromProject = (user) => {
      axios
        .post(`/admin/delete_project_membership/`, {
          user_id: user.id,
          project_id: project.id,
        })
        .then((res) => {
          setProject((project) => {
            return {
              ...project,
              users: project.users.filter((u) => u.id !== user.id),
            };
          });
          setAvailableUsers((availableUsers) => [...availableUsers, user]);
          setSuccessMessage("Removed user from project");
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <div className="show-project-content" id="users">
        <h3 className="m-0">Users in project</h3>
        <ul className="list-group list-group-flush">
          {project.users.map((user) => {
            return (
              <li className="cursor-pointer list-group-item flex-column align-items-start">
                <div className="w-100 d-flex justify-content-between align-items-start">
                  <p className="my-0 flex-shrink-1">{user.email}</p>
                  <div className="d-flex align-items-start">
                    <span
                      className="badge rounded-pill text-bg-warning mr-2 fw-normal"
                      onClick={() => removeUserFromProject(user)}
                    >
                      Remove
                    </span>
                  </div>
                </div>
              </li>
            );
          })}

          {project.users.length == 0 && (
            <span className="warning fst-italic ms-3">No users in project</span>
          )}
        </ul>

        <h3 className="mt-4">Add users</h3>
        <input
          type="text"
          className="form-control form-control-sm w-25"
          placeholder="Search for a user to add"
          id="search-user-input"
          value={searchUserInput}
          onChange={(e) => setSearchUserInput(e.target.value)}
        />
        <ul id="available-users" className="list-group list-group-flush mt-2">
          {availableUsers
            .filter(
              (u) =>
                u.email.toLowerCase().indexOf(searchUserInput.toLowerCase()) >
                -1
            )
            .map((user) => {
              return (
                <li className="cursor-pointer list-group-item flex-column align-items-start">
                  <div
                    className="w-100 d-flex justify-content-between align-items-start"
                    onClick={() => addUserToProject(user)}
                  >
                    <p id="user-email" className="my-0 flex-shrink-1">
                      {user.email}
                    </p>
                    <div className="d-flex align-items-start">
                      <span className="badge rounded-pill text-bg-primary mr-2 fw-normal">
                        Add user
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    );
  };

  const DownloadData = () => {

    const changeFormatDate = new Date("2023-05-02 12:00");
    const projectCreatedDate = new Date(project.created_at);

    const submitDownloadData = (dataset, format) => {
      axios
        .post(`/admin/projects/${project.id}/download`, {
          dataset: dataset,
          format: format,
        })
        .then((res) => {
          console.log(res.data);
          let filedata = res.data;

          var downloadLink = document.createElement("a");
          var blob =
            format === "csv"
              ? new Blob(["\ufeff", filedata])
              : new Blob(["\ufeff", JSON.stringify(filedata)]);
          var url = URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download =
            dataset + "-" + new Date().toISOString() + "." + format;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <div className="show-project-content" id="download">
        { projectCreatedDate < changeFormatDate && (
          <div className="alert alert-danger small">
            Note that this project is from before some structural changes to the database, so exporting might not work, and needs to be done manually.
          </div>
        ) }
        <h3>Download Facade Observations</h3>
        <p>
          The facade observations are the observations made either on pre-drawn
          facade sets, or drawn by the users with the Line tool
        </p>

        <div className="d-flex">
          <button
            className="btn btn-primary me-3"
            onClick={() => submitDownloadData("facades", "geojson")}
          >
            GeoJSON
          </button>

          <button
            className="btn btn-primary"
            onClick={() => submitDownloadData("facades", "csv")}
          >
            CSV
          </button>
        </div>

        <hr />

        <h3>Download Point of Interest Observations</h3>
        <p>POIs are the observations drawn by the users with the Point tool</p>
        <div className="d-flex">
          <button
            className="btn btn-primary me-3"
            onClick={() => submitDownloadData("poi", "geojson")}
          >
            GeoJSON
          </button>

          <button
            className="btn btn-primary"
            onClick={() => submitDownloadData("poi", "csv")}
          >
            CSV
          </button>
        </div>
        {/* <hr />
        <ViewData project={project}/> */}
      </div>
    );
  };

  return (
    <>
      <div className="row justify-content-center my-4">
        <div className="col-3 mt-4">
          <a href="/admin/projects" className="text-secondary small">
            ← Back
          </a>
        </div>

        <div className="col-9 mt-4">
          <div className="d-flex">
            <h1 className="mb-0">{project.name}</h1>
            {project.archived_on != null && (
              <span className="ms-2 badge rounded-pill text-bg-warning fw-normal">
                Archived
              </span>
            )}
          </div>
        </div>
      </div>

      <br />

      <div className="row justify-content-center mt-2" id="project-content">
        <div className="col-3">
          <div
            className="list-group list-group-flush"
            id="list-tab"
            role="tablist"
          >
            <a
              className={
                "list-group-item list-group-item-action cursor-pointer" +
                (selectedTab === "project" ? " active" : "")
              }
              onClick={() => setSelectedTab("project")}
            >
              Project
            </a>
            <a
              className={
                "list-group-item list-group-item-action cursor-pointer" +
                ((selectedTab === "facade-categories" || selectedTab === "poi-categories") ? " active" : "")
              }
              onClick={() => setSelectedTab("facade-categories")}
            >
              Categories
            </a>
            <a
              className={
                "list-group-item list-group-item-action list-group-item-sub cursor-pointer" +
                (selectedTab === "facade-categories" ? " active" : "")
              }
              onClick={() => setSelectedTab("facade-categories")}
            >
              Facade categories
            </a>
            <a
              className={
                "list-group-item list-group-item-action list-group-item-sub cursor-pointer" +
                (selectedTab === "poi-categories" ? " active" : "")
              }
              onClick={() => setSelectedTab("poi-categories")}
            >
              Point of Interest categories
            </a>
            <a
              className={
                "list-group-item list-group-item-action cursor-pointer" +
                (selectedTab === "facade-sets" ? " active" : "")
              }
              onClick={() => setSelectedTab("facade-sets")}
            >
              Facade Sets
            </a>
            <a
              className={
                "list-group-item list-group-item-action cursor-pointer" +
                (selectedTab === "users" ? " active" : "")
              }
              onClick={() => setSelectedTab("users")}
            >
              Users
            </a>
            <a
              className={
                "list-group-item list-group-item-action cursor-pointer" +
                (selectedTab === "download" ? " active" : "")
              }
              onClick={() => setSelectedTab("download")}
            >
              Download Data
            </a>
          </div>
        </div>

        <div className="col-9">
          {selectedTab == "project" && <ProjectSettings />}
          {selectedTab == "facade-categories" && <Categories attributes={project.facade_observation_attribute_definitions} typeName={"Facade"} />}
          {selectedTab == "poi-categories" && <Categories attributes={project.poi_observation_attribute_definitions} typeName={'POI'} />}
          {selectedTab == "facade-sets" && <FacadeSets />}
          {selectedTab == "users" && <Users />}
          {selectedTab == "download" && <DownloadData />}
        </div>
      </div>

      <div id="success-container" className={successMessage !== "" && "active"}>
        <div id="success-content" className="small">
          {successMessage}
        </div>
      </div>
    </>
  );
};

export default ShowProject;
