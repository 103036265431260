import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FacadeContext } from "../context/FacadeProvider";

const ObservationForm = ({ userId }) => {
  const {
    showObservationForm,
    handleCloseActionsContainer,
    currentImage,
    currentImageUrl,
    currentComment,
    setCurrentComment,
    submitFacadeObservation,
    floors,
    setFloors,
    formattedFloors,
    submitFailed,
    observationAttributes,
    handleImageChange,
    attributePrimary,
    setAttributePrimary,
    attributeSecondary,
    handleClickSecondaryAttribute,
  } = useContext(FacadeContext);

  let floorsStr = formattedFloors(floors);
  if (!floorsStr) floorsStr = "Not set";

  const canSubmit =
    attributePrimary || floors || currentImage || currentComment;

  return (
    <>
      {showObservationForm && (
        <div className="facade-attribute-wrapper">
          <div className="facade-attribute-container">
            <div className="facade-attribute-header d-flex justify-content-between align-items-center mb-1">
              <h4 className="facade-attribute-heading small">
                Facade Observation
              </h4>
              <div
                className="facade--close cursor-pointer"
                onClick={() => handleCloseActionsContainer()}
              >
                ×
              </div>
            </div>

            <div className="facade-attribute-content d-flex justify-content-between h-100 flex-column">
              <div className="my-2">
                {submitFailed && (
                  <div className="failed-container">
                    <h6>Oops,something has gone wrong...</h6>
                    <h3>Form Submission failed!</h3>
                    <h6>Please, reaload the page and try again</h6>
                  </div>
                )}
                <div className="attribute-container d-flex flex-wrap justify-content-between">
                  {observationAttributes.current.facadePrimary?.map((r) => {
                    let btnClasses =
                      "attribute-btn d-block btn btn-sm p-2 mb-2 half";
                    btnClasses +=
                      attributePrimary === r.code
                        ? " btn-primary"
                        : " btn-outline-primary";
                    return (
                      <button
                        key={r.code}
                        className={btnClasses}
                        onClick={() => setAttributePrimary(r.code)}
                      >
                        <p className="fw-bold mb-0">{r.code}</p>
                        <p className="small my-0">{r.description}</p>
                      </button>
                    );
                  })}
                </div>
                <div className="d-flex">
                  <div className="mb-2 form-group input-group-sm d-flex flex-grow-1 me-2">
                    <textarea
                      className="form-control"
                      placeholder="Add a note (optional)"
                      name="note"
                      onChange={(e) => setCurrentComment(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="form-group mb-2 w-25">
                    <div
                      className="image--preview-container"
                      style={{
                        backgroundImage: currentImage
                          ? `url(${currentImageUrl})`
                          : "none",
                      }}
                    >
                      <label
                        className="d-flex"
                        id="image--label"
                        htmlFor="image--input"
                      >
                        {!currentImage && (
                          <FontAwesomeIcon icon="fa-solid fa-image" />
                        )}
                      </label>
                      <input
                        type="file"
                        id="image--input"
                        name="img"
                        accept="image/*"
                        onChange={(event) =>
                          handleImageChange(event.target.files[0])
                        }
                        className="form-control-file shadow-none"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group mb-2">
                <label
                  htmlFor="floor-slider"
                  className="form-label floors-input"
                >
                  Floors: <span>{floorsStr}</span>
                </label>
                <input
                  type="range"
                  className="form-range"
                  min="0"
                  max="11"
                  step="1"
                  defaultValue="0"
                  id="floor-slider"
                  onChange={(e) => setFloors(parseInt(e.target.value))}
                ></input>
              </div>

              {observationAttributes.current.facadeSecondary.length > 0 && (
                <div>
                  <label className="form-label floors-input">
                    Additional Attributes
                  </label>
                  <div className="attribute-container attribute-container--secondary d-flex flex-wrap justify-content-between mt-2 mb-2">
                    {observationAttributes.current.facadeSecondary?.map((a) => {
                      let btnClasses =
                        "attribute-btn d-block btn btn-sm p-1 mb-2";
                      btnClasses += attributeSecondary.includes(a.code)
                        ? " btn-primary"
                        : " btn-outline-primary";

                      if (
                        observationAttributes.current.facadeSecondary.length === 2
                      )
                        btnClasses += " half";

                      return (
                        <button
                          key={a.code}
                          className={btnClasses}
                          onClick={() => handleClickSecondaryAttribute(a.code)}
                        >
                          <p className="mb-0 small">{a.code}</p>
                          <p className="extra-small my-0">{a.description}</p>
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
              
              <div className="w-100">
                <button
                  className={
                    canSubmit
                      ? "btn btn-primary d-block w-100"
                      : "btn btn-primary d-block w-100 btn-deactivated"
                  }
                  onClick={() => canSubmit && submitFacadeObservation(userId)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ObservationForm;