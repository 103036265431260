import React from "react";
import { createRoot } from "react-dom/client";
import App from "../components/App";
import ShowProject from "../showProject/ShowProject";
import CreateProject from "../createProject/CreateProject";
import UsersList from "../userList/UserList";

import "bootstrap/js/src/dropdown";
import "bootstrap/js/src/scrollspy";
import "bootstrap/js/src/tab";


import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

const container = document.getElementById("main");
const facadeFormContainer = document.getElementById("facade_form_container");
const projectFormContainer = document.getElementById("project_form_container");
const showProjectContainer = document.getElementById("show-project-container");
const usersListContainer = document.getElementById("users-list-container");

if (container) {
  let userId = container.dataset.userId;
  let mapboxAccessToken = container.dataset.mapboxAccessToken;
  let mapboxStyleSatellite = container.dataset.mapboxStyleSatellite;
  let mapboxStyleVector = container.dataset.mapboxStyleVector;
  const root = createRoot(container); // createRoot(container!) if you use TypeScript
  root.render(
    <App
      userId={userId}
      mapboxAccessToken={mapboxAccessToken}
      mapboxStyleSatellite={mapboxStyleSatellite}
      mapboxStyleVector={mapboxStyleVector}
    />
  );
}

if (showProjectContainer) {
  let project = showProjectContainer.dataset.project;
  let users = showProjectContainer.dataset.users;
  const root = createRoot(showProjectContainer);
  root.render(<ShowProject project={project} users={users} />);
}

if (projectFormContainer) {
  let projects = projectFormContainer.dataset.projects;
  const root = createRoot(projectFormContainer);
  root.render(<CreateProject projects={projects} />);
}

if (usersListContainer) {
  let users = usersListContainer.dataset.users;
  const root = createRoot(usersListContainer);
  root.render(<UsersList users={users} />);
}
