import React from "react";
import FacadeProvider from "../context/FacadeProvider";
import Sidebar from "./Sidebar";
import MapContainer from "./MapContainer";

const App = ({ userId, mapboxAccessToken, mapboxStyleSatellite, mapboxStyleVector }) => {
  
  return (
    <FacadeProvider>
      <Sidebar />
      <MapContainer
        userId={userId}
        mapboxAccessToken={mapboxAccessToken}
        mapboxStyleSatellite={mapboxStyleSatellite}
        mapboxStyleVector={mapboxStyleVector}
      />
    </FacadeProvider>
  );
};

export default App;
