import React, { useContext } from "react";
import { FacadeContext } from "../context/FacadeProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ActionsContainer = () => {
  const {
    handleOpenSidebar,
    handleClickOpenObservationsForm,
    clearForm,
    successfullySubmitted,
    selectedFacades,
    drawnFeatures,
    visualizationMode,
  } = useContext(FacadeContext);

  let icon = successfullySubmitted ? (
    <FontAwesomeIcon icon="fa-solid fa-check" />
  ) : (
    <FontAwesomeIcon icon="fa-solid fa-plus" />
  );

  let observationSelected =
    selectedFacades.length > 0 || drawnFeatures.length > 0;

  return (
    <div className="actions-container">
      <div
        className="action-btn action-btn--sm flex-start"
        onClick={() => handleOpenSidebar()}
      >
        <div className="action-btn-content d-flex">
          <FontAwesomeIcon icon="fa-solid fa-left-long" />
        </div>
      </div>
      {!visualizationMode && (
        <div
          className={
            observationSelected
              ? "add-observation-btn action-btn"
              : "add-observation-btn action-btn btn-deactivated"
          }
          onClick={() => handleClickOpenObservationsForm()}
        >
          <div className="add-observation-btn-content d-flex">{icon}</div>
        </div>
      )}
      {!visualizationMode && (
        <div className="action-btn action-btn--sm" onClick={() => clearForm()}>
          <div className="action-btn-content d-flex">
            <FontAwesomeIcon icon="fa-solid fa-minus" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ActionsContainer;
