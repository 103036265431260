import React, { useContext } from "react";
import { FacadeContext } from "../context/FacadeProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../lib/axios-instance";

function PoiForm({ userId }) {
  const {
    showPoiForm,
    setShowPoiForm,
    handleClosePoiForm,
    attributePrimary,
    setAttributePrimary,
    attributeSecondary,
    currentPoiFeature,
    currentComment,
    setCurrentComment,
    currentImage,
    handleImageChange,
    currentImageUrl,
    handleClickSecondaryAttribute,
    observationAttributes,
    clearForm,
    currentProjectId,
    setDrawnPois
  } = useContext(FacadeContext);

  const submitPoiForm = (userId) => {
    let u = userId.replaceAll('"', "");
    const formData = new FormData();
    formData.append("poi_observation[user_id]", u);
    formData.append('poi_observation[project_id]', currentProjectId)
    if (attributePrimary) formData.append(
      "poi_observation[observation_attributes_primary]",
      attributePrimary
    );
    if (attributeSecondary) formData.append(
      "poi_observation[observation_attributes_secondary]",
      attributeSecondary
    );
    formData.append("poi_observation[geometry]", JSON.stringify(currentPoiFeature.geometry))
    if (currentComment)
      formData.append("poi_observation[notes]", currentComment);
    if (currentImage)
      formData.append("poi_observation[image]", currentImage);
    
    axios.post('/poi_observations', formData)
      .then((response) => {
        if(response.status === 200) setDrawnPois(drawnPois => [...drawnPois, response.data.geometry]);
        clearForm();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {showPoiForm && (
        <div className="facade-attribute-wrapper">
          <div className="facade-attribute-container">
            <div className="facade-attribute-header d-flex justify-content-between align-items-center mb-1">
              <h4 className="facade-attribute-heading small">
                Point of interest Observation
              </h4>
              <div
                className="facade--close cursor-pointer"
                onClick={() => handleClosePoiForm()}
              >
                ×
              </div>
            </div>
            <div className="facade-attribute-content d-flex justify-content-between h-100 flex-column">
              <div className="my-2">
                <div className="attribute-container d-flex flex-wrap justify-content-between">

                  {observationAttributes.current.poiPrimary?.map((t) => {
                    let btnClasses = "attribute-btn d-block btn btn-sm p-2 mb-2 half";
                    btnClasses +=
                      attributePrimary === t.code ? " btn-primary" : " btn-outline-primary";
                    return (
                      <button
                        key={t.code}
                        className={btnClasses}
                        onClick={() => setAttributePrimary(t.code)}
                      >
                        <p className="fw-bold mb-0">{t.code}</p>
                        <p className="small my-0">{t.description}</p>
                      </button>
                    );
                  })}
                </div>

                <div className="d-flex">
                  <div className="mb-2 form-group input-group-sm d-flex flex-grow-1 me-2">
                    <textarea
                      className="form-control"
                      placeholder="Add a note (optional)"
                      name="note"
                      onChange={(e) => setCurrentComment(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="form-group mb-2 w-25">
                    <div
                      className="image--preview-container"
                      style={{
                        backgroundImage: currentImage
                          ? `url(${currentImageUrl})`
                          : "none",
                      }}
                    >
                      <label
                        className="d-flex"
                        id="image--label"
                        htmlFor="image--input"
                      >
                        {!currentImage && (
                          <FontAwesomeIcon icon="fa-solid fa-image" />
                        )}
                      </label>
                      <input
                        type="file"
                        id="image--input"
                        name="img"
                        accept="image/*"
                        onChange={(event) =>
                            handleImageChange(event.target.files[0])
                          }
                        className="form-control-file shadow-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {observationAttributes.current.poiSecondary.length > 0 && (
              <div>
                <label className="form-label floors-input">
                  Additional Attributes
                </label>
                <div className="attribute-container attribute-container--secondary d-flex flex-wrap justify-content-between mt-2 mb-2">
                  {observationAttributes.current.poiSecondary?.map((a) => {
                    let btnClasses = "attribute-btn d-block btn btn-sm p-1 mb-2";
                    btnClasses +=
                      attributeSecondary.includes(a.code)
                        ? " btn-primary"
                        : " btn-outline-primary";
                      if (observationAttributes.current.poiSecondary.length === 2) btnClasses += " half" 
                    return (
                      <button
                        key={a.code}
                        className={btnClasses}
                        onClick={() => handleClickSecondaryAttribute(a.code)}
                      >
                        <p className="mb-0 small">{a.code}</p>
                        <p className="extra-small my-0">{a.description}</p>
                      </button>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="w-100">
              <button
                className="btn btn-primary d-block w-100"
                onClick={()=> submitPoiForm(userId)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PoiForm;
