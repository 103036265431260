import React, { useState, useEffect } from "react";
import axios from "../lib/axios-instance";
import { getGeocoderResults } from "./utils";
import { defaultPrimaryAttributes } from "../utils/utils";

import { point, featureCollection } from "@turf/helpers";
import { slugify } from "../showProject/utils";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoibmluYWNlY2lsaWUiLCJhIjoiY2thcXNicW4yMDNmMzJzbzVtcnBjZWNzOSJ9.a3-LyH4VeC8vprevn4BVnw";
const CreateProject = ({ projects }) => {
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [isValidSlug, setIsValidSlug] = useState(true);
  const [isValidName, setIsValidName] = useState(true);
  const [currentNames, setCurrentNames] = useState([]);
  const [currentSlugs, setCurrentSlugs] = useState([]);
  const [createDefaultAttributes, setCreateDefaultAttributes] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [chosenCity, setChosenCity] = useState([]);

  useEffect(() => {
    JSON.parse(projects).map((p) => {
      setCurrentNames((currentNames) => [...currentNames, p.name]);
      setCurrentSlugs((currentSlugs) => [...currentSlugs, p.slug]);
    });
  }, [projects]);

  const handleChangeCity = (e) => {
    setSearchTerm(e.target.value);
    getGeocoderResults(e.target.value, MAPBOX_TOKEN).then((features) => {
      setResults({ results: features });
    });
  };

  const handleNameChange = (e) => {
    let slugifiedName = slugify(e.target.value);
    setName(e.target.value);
    if (!currentNames.includes(e.target.value)) {
      setIsValidName(true);
      setSlug(slugifiedName);
      !currentSlugs.includes(slugifiedName)
        ? setIsValidSlug(true)
        : setIsValidName(false);
    } else {
      setIsValidName(false);
    }
  };

  const handleSlugChange = (e) => {
    setSlug(e.target.value);
    if (!currentSlugs.includes(e.target.value)) {
      setIsValidSlug(true);
    } else {
      setIsValidSlug(false);
    }
  };

  const handleClick = (city) => {
    setSearchTerm(city.name);
    setChosenCity(city);
    setResults([]);
  };

  const handleDefaultAttributeChange = (e) => {
    setCreateDefaultAttributes(e.target.checked);
  }

  const formatAttribute = (attribute, projectId) => {
    let data = {};

    let properties = {
      order: attribute.order,
      code: attribute.code,
      description: attribute.description,
      is_primary: attribute.primary,
      is_secondary: !attribute.primary,
      project_id: projectId,
      help_text: attribute.helpText
    }

    data['facade_observation_attribute_definition'] = properties;
    return data;
  }


  const submitProject = (e) => {
    e.preventDefault();
    let data = { slug: slug };
    axios
      .post("admin/validate_project_slug", data)
      .then((res) => {
        if (JSON.stringify(res.data) == 'true') {
          const feat = point(chosenCity.center);
          const fc = featureCollection([feat]);
          const formData = new FormData();
          formData.append("projects[name]", name);
          formData.append("projects[slug]", slug);
          formData.append("projects[city]", chosenCity.name);
          formData.append("projects[coordinates]", JSON.stringify(fc));
          axios
          .post("/admin/projects/", formData)
          .then((projectRes) => {

            if(createDefaultAttributes) {
              const promises = defaultPrimaryAttributes.map((attribute) => {
                const formattedAttribute = formatAttribute(attribute, projectRes.data.project.id);
                return axios.post(`/admin/attributes`, formattedAttribute);
              });

              Promise.all(promises).then((res) => {
                if (projectRes.data.redirect) window.location.href = projectRes.data.redirect_url;
              }).catch((err) => {
                console.log(err);
              })
            
            } else {
              if (projectRes.data.redirect) window.location.href = projectRes.data.redirect_url;
            }
          })
          .catch((err) => {
            console.log(err);
          });
        } else {
          setIsValidSlug(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let createBtnStyle =
    chosenCity.length !== 0 && isValidSlug && isValidName
      ? "btn btn btn-primary"
      : "btn btn btn-primary " + "disabled";

  return (
    <div>
      <h1 class="my-4">New project</h1>

      <form>
        <div className="row g-3" >
          <div className="col-md-8">
            <div className="form-group mb-4">
              <label>Name</label>
              <input
                className="form-control mb-4"
                name="name"
                value={name}
                onChange={(e) => handleNameChange(e)}
              />
              {!isValidName && <small>The selected name is already in use</small>}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group mb-4">
              <label>Slug</label>
              <input
                className="form-control mb-4"
                name="slug"
                value={slug}
                onChange={(e) => handleSlugChange(e)}
              />
              {!isValidSlug && <small>The selected slug is already in use</small>}
            </div>
          </div>
        </div>
        
        <div className="form-group mb-4">
          <label>City</label>
          <input
            className="form-control mb-4"
            value={searchTerm}
            onChange={(e) => handleChangeCity(e)}
          />
          {results.results?.length > 0 && (
            <ul className="result-list bg-light shadow-sm rounded-bottom">
              {results.results.map((r) => {
                return (
                  <li key={r.id} onClick={() => handleClick(r)}>
                    {r.name}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div class="form-check form-group mb-4">
          <input class="form-check-input" type="checkbox" id="create-default-attributes" checked={createDefaultAttributes} onChange={(e) => handleDefaultAttributeChange(e)} />
          <label class="form-check-label" for="create-default-attributes">
            Create default facade categories (A-E)
          </label>
        </div>

        <button className={createBtnStyle} onClick={submitProject}>
          Create
        </button>
      </form>
    </div>
  );
};

export default CreateProject;
