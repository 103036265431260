'use strict';

// Extract the csrf token from the document's metadata and return an object
// containing the populated header
//
// Allows non-GET requests to be made within the Rails CSRF protection
// constraints
export default function() {
  const meta = document.getElementsByTagName('meta');

  if (!meta || !meta['csrf-token']) return {};

  return {
    'X-CSRF-Token': meta['csrf-token'].content
  }
}
