import React, { useState, useEffect } from "react";
import axios from "../lib/axios-instance";

const UserList = (props) => {
  const [users, setUsers] = useState(JSON.parse(props.users));

  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (successMessage !== "")
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
  }, [successMessage]);

  const handleAdminChange = (user, checked) => {
    console.log(checked);

    let data = {
      user: {
        admin: checked,
      },
    };
    axios
      .put(`/admin/users/${user.id}/update`, data)
      .then((res) => {
        let updatedUser = res.data;
        setUsers(users => users.map((u) => {
          if(u.id === updatedUser.id) u = updatedUser;
          return u;
        }))

        setSuccessMessage("User role updated");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <ul class="list-group list-group-flush">
        {users.map((user) => {
          return (
            <li class="list-group-item flex-column align-items-start">
              <div class="w-100 d-flex justify-content-between align-items-start">
                <p class="my-0 flex-shrink-1">{user.email}</p>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input cursor-pointer"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    checked={user.admin}
                    onChange={(e) => handleAdminChange(user, e.target.checked)}
                  />
                  <label
                    class="form-check-label small"
                    for="flexSwitchCheckDefault"
                  >
                    Admin
                  </label>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      <div id="success-container" className={successMessage !== "" && "active"}>
        <div id="success-content" className="small">
          {successMessage}
        </div>
      </div>
    </>
  );
};

export default UserList;
