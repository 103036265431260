import React, { useEffect, useContext } from "react";
import { FacadeContext } from "../context/FacadeProvider";
import axios from "../lib/axios-instance";
import { windowUrl, path, setUrl, resetUrl } from "../utils/utils";
import LoadingImage from '/images/loading.svg';

const Sidebar = () => {
  const {
    projects,
    isSidebarOpen,
    setIsSidebarOpen,
    setSelectedFacadeSet,
    currentFacadeSetId,
    setCurrentFacadeSetId,
    getProjects,
    setCurrentProjectId,
    setSelectedProject,
    isLoading,
    setIsLoading
  } = useContext(FacadeContext);

  useEffect(() => {
    if (windowUrl.slice(0, 13) === path) {
      getFacadesByParams(windowUrl.substring(13));
    } else {
      getProjects();
    }
  }, []);

  const getFacadesByParams = async (slug, fromClick = false) => {
    setIsLoading(true);
    const response = await axios.get("/facade_sets", {
      params: { slug: slug },
    });
    if (response.data[0]) {
      setIsLoading(false);
      setIsSidebarOpen(true);
      setSelectedFacadeSet(response.data[0].geometry);
      setCurrentFacadeSetId(response.data[0].id);
      setCurrentProjectId(response.data[0].project_id);

      if(fromClick) {
        setUrl(facade.slug);
      }
    } else {
      resetUrl();
      getProjects();

      setIsLoading(false);
    }
  };

  const handleFacadesClick = (facade) => {
    getFacadesByParams(facade.slug, true);
  };

  const handleProjectClick = (project) => {
    setCurrentProjectId(project.id);
    setUrl(project.slug);
    setSelectedProject(project);
    setIsSidebarOpen(true);
  };

  const FacadeList = ({ facades }) => {
    return (
      <ul className="list-unstyled">
        {facades?.map((facade) => {
          if (facade.archived_on === null)
            return (
              <li
                className={
                  currentFacadeSetId === facade.id
                    ? "cursor-pointer mb-2 active"
                    : "cursor-pointer mb-2"
                }
                onClick={() => handleFacadesClick(facade)}
                key={facade.id}
              >
                <div className="d-flex">
                  <div className="circle-icon">
                    <span className="circle-icon-content">FS</span>
                  </div>
                  <span>{facade.name}</span>
                </div>
              </li>
            );
        })}
      </ul>
    );
  };

  return (
    <div id="sidebar-container" className={isSidebarOpen ? "closed" : "open"}>
      <h4 className="text-muted mb-3">
        Select a project and facade set or city
      </h4>
      <div className="place-container">
        {projects?.map((project) => {
          return (
            <li key={project.id} className="list-unstyled mb-4">
              <h2>{project.name}</h2>
              {project.coordinates && (
                <ul className="list-unstyled">
                  <li
                    className="cursor-pointer mb-2"
                    onClick={() => handleProjectClick(project)}
                  >
                    <div className="d-flex">
                      <div className="circle-icon">
                        <span className="circle-icon-content">C</span>
                      </div>
                      <span>{project.city}</span>
                    </div>
                  </li>
                </ul>
              )}
              {project.facade_sets.length > 0 && (
                <FacadeList facades={project.facade_sets} />
              )}
            </li>
          );
        })}
      </div>
      <div id="loading-container" className={isLoading ? 'active' : ''}>
        <img id="loading-spinner" src={LoadingImage} />
      </div>
    </div>
  );
};

export default Sidebar;
