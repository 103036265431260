export let windowUrl = window.location.pathname;

export const path = "/facade_sets/";

export const setUrl = (currentSlug) => {
  window.history.pushState(windowUrl, "Title", `/facade_sets/${currentSlug}`);
};

export const resetUrl = () => {
  window.history.pushState(windowUrl, "Title", "/");
};

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export const categoryColors = [
  "#a1dab4",
  "#fed976",
  "#fd8d3c",
  "#b3e467",
  "#41b6c4",
  "#d9c0c7",
];

export const addColorToFacades = (facades) => {
  facades.forEach((facade, index) => {
    facade.color = categoryColors[index % categoryColors.length];
  });
  return facades;
};

export const formattedFloors = (floors) => {
  let fFloors = floors;
  if (floors === 0) fFloors = null;
  if (floors === 11) fFloors = "11+";

  return fFloors;
};

export const defaultPrimaryAttributes = [
  {
    code: "A",
    description: "Active",
    help_text: "Small units, many doors (15-20 doors per 100m)\nLarge variation in function\nNo blind or few passive units\nLots of character in facade relief\nPrimarily vertical facade articulation\nGood details and materials",
    order: 1,
    primary: true
  },
  {
    code: "B",
    description: "Pleasant",
    help_text: "Relatively small units (10-14 doors per 100)\nSome variation in function\nFew blind or passive units\nModerate facade relief\nMany details, articulation",
    order: 2,
    primary: true
  },
  {
    code: "C",
    description: "Mixed",
    help_text: "Large and small units (6-10 doors per 100m)\nModest variation in function\nSame blind and passive units\nModest facade relief\nFew details",
    order: 3,
    primary: true
  },
  {
    code: "D",
    description: "Dull",
    help_text: "Large units, few doors (2-5 doors per 100m)\nAlmost no variation in function\nMany blind /uninteresting units/dark glass with poor contact between outdoor/indoor\nFew or no details",
    order: 4,
    primary: true
  },
  {
    code: "E",
    description: "Inactive",
    help_text: "Large units, few or no doors (0-2 doors per 100m)\nNo visible variation in function\nBlind or passive units\nUniform facades, no details\nBlank walls\nNothing to look at\n",
    order: 5,
    primary: true
  }
];