import axios from "../lib/axios-instance";

export const getGeocoderResults = async (query, MAPBOX_TOKEN) => {
    const searchTerm = encodeURIComponent(query);
    try {
      const { data } = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchTerm}.json`,
        {
          params: {
            access_token: MAPBOX_TOKEN,
            types: "place",
            language: "en",
          },
        }
      );
      const results = data.features.map(({ id, center, place_name_en }) => {
        return {
          id,
          center,
          name: place_name_en,
        };
      });

      return results;
    } catch (error) {
      throw error;
    }
  };